<template>
  <div >
    <header-slot></header-slot>
    <!-- Navigation -->
    <b-nav card-header pills class="m-0">

      <!-- ACTIVES -->
      <b-nav-item
        :to="{ name: 'boostcredit-call-rounds' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >ACTIVES</b-nav-item>

      <!-- HOLD -->
      <b-nav-item
        :to="{ name: 'boostcredit-call-rounds-hold' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >HOLD</b-nav-item>

      <!-- STAND BY -->
      <b-nav-item
        :to="{ name: 'boostcredit-call-rounds-stand-by' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >STAND BY</b-nav-item>
    </b-nav>

   <!-- Dynamic Route -->
    <b-card no-body class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name"></router-view>
    </b-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>